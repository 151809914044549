'use client';
import React, { useCallback } from 'react';
import PFLink from '../PFLink';
import { trackOnClick } from '../../common/tracking';

type Props = {
  children: JSX.Element | JSX.Element[];
  trackingData?: any;
  className?: string;
  index?: number;
  listType: string;
  canonicalUrl: string;
  title: string;
  id: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  fakeLink?: boolean;
};

export function ArticleLink(props: Props) {
  const {
    children,
    canonicalUrl,
    title,
    id,
    trackingData,
    className,
    index,
    listType,
    item_category,
    item_category2,
    item_category3,
    fakeLink,
  } = props;

  const onClick = useCallback(() => {
    trackOnClick([
      {
        item_name: title,
        item_id: id,
        item_brand: 'standard',
        item_category: item_category,
        item_category2: item_category2,
        item_category3: item_category3,
        item_list_name: listType,
        item_list_id: listType,
        index: index,
        quantity: 1,
      },
    ]);
  }, [
    id,
    index,
    item_category,
    item_category2,
    item_category3,
    listType,
    title,
  ]);

  return (
    <PFLink
      fakeLink={fakeLink}
      onClick={onClick}
      className={className}
      href={canonicalUrl.replace('https://www.promiflash.de', '')}
      title={title}
    >
      {children}
    </PFLink>
  );
}
